.AboutUsCard {
    width: 368px;
    margin: 57px 28px;
}

.AboutUsCard__name {
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-weight: 600;
    line-height: 153.523%;
    text-transform: uppercase;
    margin-bottom: 32px;
    margin-top: 30px;
}

.AboutUsCard__text {
    width: 100%;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 142.523%;
    display: flex;
    justify-content: center;
}

.AboutUsCard__name__blue {
    color: #415DF1;
}


@media(max-width:510px) {
    .AboutUsCard{
        margin-left:0px !important;
        margin-right:0px !important;
    }
    .AboutUsCard img{
        width: 100%;
    }
    .AboutUsCard__text{
        text-align: center;
    }
}
