.box {
    display: flex;
    justify-content: center;
}
.box__cont{
    width: 414px;
    padding-bottom: 20px;

}
.box img{
    width: 100%;
}

.box__title {
    color: #000;
    font-family: Inter;
    font-size: 24.412px;
    font-weight: 700;
    line-height: 150.523%;
    margin: 19px 0px 0px 0px !important;
}

.box__btn {
    width: max-content;
    margin-top: 40px;
    padding-bottom: 7px;
    background-color: white;
    border:none;
    border-bottom:4px solid #FFD767;
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
}
.box__fullText{
    margin: 0px !important;
    font-size: 16px;
    
}