/* fonts */
@font-face {
  font-family: IBM Plex Sans;
  src: url("../public/IBMPlexSans-Light.ttf");
}

@font-face {
  font-family: Inter;
  src: url("../public/Inter-Light.ttf");
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}


.header {
  color: #E5E5E5;
  background: rgba(9, 10, 14, 1);
}

.header__navbar {
  position: relative;
  padding: 37px 47px 0px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(229, 229, 229, 1);
}

.menu {
  display: flex;
}


.header__navbar__phone {
  display: none;
}

.header__navbar__phone:hover {
  cursor: pointer;
}

.header__navbar__phone-menu {
  border-bottom: 2px solid #E6C362;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #090A0E;
  width: 100%;
  z-index: 1;
  padding-bottom: 10px;

}

.menu__link_phone {
  display: flex;
  justify-content: center;
  margin-left: 0px !important;
  font-size: 30px !important;
  margin-top: 40px;
  color: #E5E5E5;
  font-family: IBM Plex Sans;
  font-size: 16px;
  line-height: 50px;
}

.menu__link_phone span {
  border-bottom: #090A0E 3px solid;
  transition: 0.4s;
}

.menu__link_phone span:hover {
  transition: 0.4s;
  cursor: pointer;
  border-bottom: #415DF1 3px solid;
}

.closeBnt {
  text-align: end;
  padding: 10px 20px 0px 20px;
  font-size: 40px;
  cursor: pointer;
  color: #E6C362;
}
.closeBnt span {
  padding: 10px 20px 0px 20px;
  font-size: 40px;
  cursor: pointer;
  color: #E6C362 !important;
}

.phoneMenu__btn-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header__row__scrollText {
  display: none;
  color: rgba(255, 255, 255, 0.27);
  font-family: Inter;
  font-size: 15.672px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding-bottom: 40px;
}




.menu__link {
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #E5E5E5;
  font-family: IBM Plex Sans;
  font-size: 16px;
  height: 30px;
  margin-left: 38px;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.menu__link::before,
.menu__link::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
}

.menu__link::before {
  background-color: #415DF1;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.menu__link::after {
  content: attr(data-replace);
  height: 100%;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.menu__link:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}


.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* button */
.header__buttons__btnLogin {
  font-family: Inter;
  width: 127px;
  height: 47px;
  border: 1px solid #E6C362;
  background-color: rgba(9, 10, 14, 1);
  color: rgba(229, 229, 229, 1);
  margin-right: 20px;
  transition: 0.4s;
}

.header__buttons__btnLogin:hover {
  background-color: #E6C362;
  color: black;
  cursor: pointer;
  transition: 0.4s;
}

.header__buttons__btnRegister {
  font-family: Inter;
  font-weight: 400;
}


.mainBtn {
  background-color: rgba(65, 93, 241, 1);
  color: rgba(255, 255, 255, 1);
  width: 156px;
  height: 47px;
  border: none;
  transition: 0.4s;
}

.mainBtn:hover {
  cursor: pointer;
  transition: 0.4s;
  background-color: #677de9;
}

.mainBtn2 {
  color: #E5E5E5;
  background-color: rgba(9, 10, 14, 1);
  border: 0px;
  font-family: Inter;
  font-size: 15.672px;
  font-weight: 400;
  transition: 0.4s;
}

.mainBtn2:hover {
  cursor: pointer;
  transition: 0.4s;
  border: 3px solid #E6C362;
}

.header__btn1 {
  width: 285px;
  height: 54px;
  padding: 5.558px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 15.672px;
}

.header__btn2 {
  margin-left: 32px;
  border: 3px solid rgba(9, 10, 14, 1);
  border-bottom: 3px solid #E6C362;
}

.main__btn1 {
  display: flex;
  width: 285.522px;
  height: 54.881px;
  padding: 5.558px;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 15.672px;
}

.main__aboutUs__btn {
  font-family: Inter;
  font-size: 15.672px;
  font-weight: 400;
  width: 216px;
}

/* button */
.header__row {
  padding-left: 50px;
  display: flex;
}



.header__col {
  flex: 0.333%;
}

.header__col__text {
  margin-top: 70px;
}

.header__col__title2 {
  max-width: 566px;
  max-height: 90px;
  color: #E5E5E5;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.523%;
}

.header__col__buttons {
  display: flex;
  margin-top: 56px;
  margin-bottom: 203px;
}

.header__col__title {
  font-family: Inter;
  font-size: 72.022px;
  line-height: 104.023%;
}

.header__col__robo {
  position: relative;
  top: +6px;
  left: +20px;
}

.header__col__blue,
.main__partners__item__blue,
.main__advantages-RPA__blue {
  color: #415DF1;
}

.header__col_img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__col_img img {
  width: 100%;
}



/* main */
.main__partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 107px;
}

.main__partners__text {

  margin-left: 96px;
  margin-right: 135px;
  font-size: 35px;
  font-family: Inter;
  font-weight: 700;
}

.main-content__partners__item {
  margin-right: 94px;
  margin-top: 20px;
}

.main-content__partners__item:hover {
  cursor: pointer;
}

.main__whyRPA {
  display: flex;
  align-items: center;
}

.main__whyRPA__item {
  margin-bottom: 47px;
  display: flex;
  justify-content: center;
}

.main__whyRPA__item1__title {
  font-family: Inter;
  font-size: 32.667px;
  font-style: normal;
  font-weight: 900;
  line-height: 150.523%;
  margin: 0px;
  padding: 0px;
}

.main__whyRPA__item1__p {
  max-width: 525px;
  max-height: 162px;
  color: #000;
  font-family: Inter;
  font-size: 18px;
  line-height: 150.523%;
  padding: 44px 0px 44px 0px;
  margin: 0px;
}

.main__whyRPA__item1__body {
  margin-left: 125px;
}
.main__advantages-RPA {
 
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: -300px;
}
.main__advantages-RPA__title {
  position: absolute;
  top: -24px;
  left: 53%;
  font-family: Inter;
  font-size: 32.667px;
  font-weight: 700;
  line-height: 150.523%;
}



.RPA__block {
  display: flex;
  align-items: center;
  max-width: 556px;
}

.RPA__block__title {
  margin-left: 12px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 150.523%;
}

.RPA__block__p {
  margin-left: 52px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 150.523%;
}

.RPA__block__item1,
.RPA__block__item2,
.RPA__block__item3,
.RPA__block__item4,
.RPA__block__item5{
  transition: 0.4s;
}

.RPA__block__item1 {
  position: absolute;
  top: +160px;
  right: 40%;
}

.RPA__block__item2 {
  position: absolute;
  top: +330px;
  left: 62%;
}

.RPA__block__item3 {
  position: absolute;
  top: +560px;
  right: 40%;
}

.RPA__block__item4 {
  width: 518px;
  position: absolute;
  top: +920px;
  right: 43%;
}

.RPA__block__item5 {
  position: absolute;
  top: +730px;
  left: 62%;
}
.vector2{
  display: none;
  justify-content: center;
}




.main__projects__title {
  margin: 77px 0px 86px 0px;
  text-align: center;
  font-family: Inter;
  font-size: 32.667px;
  font-weight: 700;
  line-height: 150.523%;
}



.carusel {
  padding: 2rem;
  padding-top: 0;
  padding-bottom: 0;
  color: white;
}


.slick-slide div {
  margin: 0px 22.5px ;
}

.slick-dots li.slick-active button:before {
  color: #415DF1 !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  opacity: 1 !important;
  color: #D9D9D9 !important;
  font-size: 12px !important;
}

.slick-prev:before,
.slick-prev {
  display: none !important;
}

.slick-next {
  top: -76px !important;
  right: +50px !important;
  color: #415DF1;
  background-color: #415DF1;
}

.slick-next:before {
  opacity: 1 !important;
  content: url(./img/Arrow\ 1.svg) !important;
}

.title {
  font-family: Inter;
  font-size: 32.667px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.523%;
  text-align: center;
  margin-top: 306px;
}

.main__aboutUs__card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main__aboutUs__Btn-cont {
  display: flex;
  justify-content: center;
  margin-top: 57px;
  margin-bottom: 20px;
}

/* footer */
.footer {
  background: #090A0E;
}

.footer__row {
  padding: 68px 213px 113px 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.footer__col__li li {
  list-style-type: none;
  color: rgba(231, 231, 231, 0.64);
  font-family: IBM Plex Sans;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 50px;
}

.footer__col__text1 {
  margin-top: 42px;
  width: 467px;
  color: #E5E5E5;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 150.523%;
}

.footer__col__social-media li {
  display: flex;
  margin-bottom: 17px;
  align-items: center;
  list-style-type: none;
  color: #BBB;
  font-family: Arial;
  font-size: 15.337px;
  font-weight: 400;
  line-height: 21.235px;
}

.footer__col__social-media li img {
  margin-right: 9px;
}

.footer__col__сity {
  margin-top: 30px;
  color: #FFF !important;
  font-family: Arial;
}

.footer__row1 {
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #BBB;
  font-family: Arial;
  list-style-type: none;
  font-size: 14.157px;
  font-weight: 400;
  position: relative;
}

.footer__line {
  margin: 68px 50px 54px 50px;
  border-top: 3px solid rgba(255, 255, 255, 0.20);
}

.footer__row1__col {
  padding-bottom: 5px;
  height: max-content;
  margin: 0px 25px 0px 25px;

}

.footer__row1__line {
  position: relative;
  top: +7px;
  border-left: 2px solid rgba(255, 255, 255, 0.20);
  height: 28px;
}

.footer__row1__line__last {
  border: none;
}

.footer__copy {
  text-align: center;
  color: #BBB;
  text-align: center;
  font-family: Arial;
  font-size: 14.157px;
  font-weight: 400;
  line-height: 23.595px;
  padding-bottom: 60px;
  padding-top: 40px;
}
.footerlogo__phone{
  justify-content: start;
}
/* footer */

/* form */
.form {
  margin: 30px auto;
  background-color: #15172b;
  border-radius: 20px;
  box-sizing: border-box;
  height:max-content;
  padding: 20px;
  width:max-content;
}

.title {
  color: #eee;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

.subtitle {
  color: #eee;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.cut-short {
  width: 50px;
}
.input__name__error{
  border-bottom: 1px solid red;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #dc2f55;
}

.submit {
  background-color: #08d;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  text-align: center;
  width: 100%;
}

.submit:active {
  background-color: #06b;
}

/* form */





@media(min-width:1100px) {
  .footerlogo__phone{
    display: none;
  }
  .header__navbar__phone-menu {
    display: none;
  }
  .header__navbar__phone-menu{
    display: none !important;
  }
  
}

@media(max-width:1500px) {
  .main__whyRPA__item1__p {
    max-height: max-content;
  }

  .RPA__block__item2 {
    position: absolute;
    top: +330px;
    left: 64%;
  }

}


@media(max-width:1100px) {
  .footerlogo__phone{
    display: flex;
    width: 100%;
  }
  .footer__logo{
    display: none;
  }
  .footer__row{
    padding:40px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer__col__li{
    order:1;
    display: flex;
  }
  .menu__link{
    margin: 0px 10px;
  }
  .footer__col__social-media{
    order: 2;
  }
  .footer__col__text{
    order: 3;
  }
  .footer__col__text1{
    width: 100%;
  }
  .footer__col{
    margin: 20px 0px;
  }
  .footer__col__social-media li{
    display: flex;
    justify-content: center;
  }
  .main__advantages-RPA{
    margin-left: 0px !important;
  }
  .main__whyRPA {
    display: block !important;
  }
  .vector1{
    display:none;
  }
  .vector2{
    display: flex;
  }
  .RPA__block {
    display: flex;
    align-items: center;
    max-width: 400px;
  }
  .RPA__block__title {
    margin-left: 10px;
    font-size:16px;
  }
  
  .RPA__block__p {
    margin-left: 12px;
    font-size: 13px;
  }
  .RPA__block img{
    width: 80px;
  }


  /*  */
  .RPA__block__item1 {
    position: absolute;
    top: +60px;
    right: 20%;
  }
  
  .RPA__block__item2 {
    position: absolute;
    top: +240px;
    left: 30%;
  }
  
  .RPA__block__item3 {
    position: absolute;
    top: +400px;
    right: 30%;
  }
  
  .RPA__block__item4 {
    width: 518px;
    position: absolute;
    top: +560px;
    right: 20%;
  }
  
  .RPA__block__item5 {
    position: absolute;
    top: +710px;
    left: 36%;
  }
  /*  */

}

@media(max-width:1000px) {
  .main__partners__text {
    display: flex;
  }

  .main__partners__item__blue {
    margin-right: 10px;
  }

  .main__partners {
    flex-direction: column;
  }

  .main-content__partners__item {
    margin: 25px 0px;

  }

  .header__col__title {
    font-size: 52.022px;
  }

  .header__row__scrollText {
    display: block;
  }

  .header__row {
    display: block !important;
  }

  .header__col__buttons {
    margin-bottom: 0px !important;
  }
}

@media(max-width:1100px) {

  .menu,
  .header__buttons {
    display: none;
  }

  .header__navbar__phone {
    display: block;
  }

}


@media(max-width:800px) {
  .main__advantages-RPA__title{
    display: flex;
    position:static;    
    justify-content: center;
  }
  .footer__row1__line{
    display: none;
  }
  .footer__row1__col{
    padding-bottom: 30px;
  }
  .main__advantages-RPA{
    margin-left:0px;
  }
  .main__whyRPA__item1__body {
    margin-left: 10px;
    align-items: center;
  }

  .whyRPAimg {
    width: 100%;
  }

  .header__row {
    padding-left: 20px;
  }

  /*  */
  .RPA__block__item1 {
    position: absolute;
    top: +90px;
    right: 10%;
    z-index: 1;
  }
  
  .RPA__block__item2 {
    position: absolute;
    top: +290px;
    left: 30%;
  }
  
  .RPA__block__item3 {
    position: absolute;
    top: +440px;
    right: 30%;
  }
  
  .RPA__block__item4 {
    width: 518px;
    position: absolute;
    top: +620px;
    right: 20%;
  }
  
  .RPA__block__item5 {
    position: absolute;
    top: +760px;
    left: 36%;
  }
  /*  */
}

@media(max-width:570px) {
 
  .main__advantages-RPA{
    overflow:hidden;
  }
  .header__col__buttons {
    display: block !important;

  }

  .header__btn1 {
    margin-bottom: 20px;
  }

  .header__btn2 {
    margin-left: 0px !important;
    height: 48px;
  }
    .RPA__block__item1 {
      right: -5%;
    }
    .RPA__block__item2 {
      left: 20%;
    }
    .RPA__block__item3 {
      right: 9%;
    }
    .RPA__block__item4 {
      right: 10%;
    }
    .RPA__block__item5 {
      left: 20%;
    }
}

@media(max-width:450px) {
  .form {
    width:100%;
  }
  
  .footer__row1{
    display: block;
    padding-left: 20px;
  }
  .main__btn1{
    width:max-content;
    margin: 0px auto;
  }
  .header__buttons__btnLogin__phone {
    margin-right: 10px;
  }

  .header__btn1 {
    width: 200px;
  }

  .header__row,
  .header__navbar {
    padding-left: 20px;
  }

  .header__col__title {
    font-size: 35.022px !important;
  }

  .header__col__title2 {
    max-height: max-content;
  }

  .header__col__robo__icons {
    width: 40px;
  }
     /*  */
     .RPA__block__item1 {
      right: 1%;
    }
    .RPA__block__item2 {
      left: 15%;
    }
    .RPA__block__item3 {
      left: 15%;
    width: 200px;

    }
    .RPA__block__item4 {
      right: -5%;
    }
    .RPA__block__item5 {
      left: 14%;
    }
    .RPA__block {
      max-width: 300px;
    }
    .RPA__block__title {
      margin-left: 10px;
      font-size:14px;
    }
    
    .RPA__block__p {
      margin-left: 10px;
      font-size: 9px;
    }
    .RPA__block img{
      width: 60px;
    }
    /*  */
 
}
@media(max-width:400px) {
  .footer__col__social-media li{
    justify-content: start;
  }
  .RPA__block__item1 {
    left: +9%;
    top: +110px;
  }
  .RPA__block__item2 {
    left: 15%;
  }
  .RPA__block__item3 {
    left: +13%;
  }
  .RPA__block__item4 {
    left: +14%;
  }
  .RPA__block__item5 {
    left: 14%;
  }
  .header__col__title{
    font-size: 20px !important; 
  }
  .header__col__robo__icons{
    width: 30px;
  }
  .header__col__robo{
    top: 0px;
    left: 10px;
  }
}
@media(max-width:300px) {
  .RPA__block__item4 {
    width: 200px;
    left: +14%;
    top: +600px;
  }
}

